// @flow
import * as React from 'react';
import {
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  // $FlowFixMe
} from '@mui/material';

import FieldContainer from 'Components/FieldContainer';

// $FlowFixMe
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { Controller } from 'react-hook-form';

type Props = {|
  errors: Object,
  control: Object,
  activeStep: number,
|};

function Step1(props: Props): React.Node {
  const { activeStep, errors, control } = props;

  return (
    <Box sx={{ display: activeStep === 0 ? 'auto' : 'none' }}>
      <FieldContainer htmlFor="siteId" label="Site ID" error={errors.siteId}>
        <Controller
          name="siteId"
          control={control}
          render={({ field }) => (
            <TextField {...field} variant="outlined" fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="siteName"
        label="Site Name"
        error={errors.siteName}
      >
        <Controller
          name="siteName"
          control={control}
          render={({ field }) => (
            <TextField {...field} variant="outlined" fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="siteLocation"
        label="Site Location"
        error={errors.siteLocation}
      >
        <Controller
          name="siteLocation"
          control={control}
          render={({ field }) => (
            <TextField {...field} variant="outlined" fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="reportStartDate"
        label="Report start date"
        error={errors.reportStartDate}
      >
        <Controller
          name="reportStartDate"
          control={control}
          defaultValue=""
          render={({ field: { ref, ...rest } }) => (
            <MobileDatePicker
              id="reportStartDate-dialog"
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => <TextField {...params} fullWidth />}
              {...rest}
            />
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="fiberProvider"
        label="The Client (Fiber Provider)"
        error={errors.fiberProvider}
      >
        <Controller
          control={control}
          name="fiberProvider"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                value="Hyperoptic Ltd"
                control={<Radio />}
                label="Hyperoptic Ltd"
              />

              <FormControlLabel
                value="COMMUNITY FIBE LIMITED / ZS COMMUNICATIONS LTD"
                control={<Radio />}
                label="COMMUNITY FIBE LIMITED / ZS COMMUNICATIONS LTD"
              />
            </RadioGroup>
          )}
        />
      </FieldContainer>
    </Box>
  );
}

export default Step1;
