/* eslint-disable no-nested-ternary */
// @flow
import * as React from 'react';

import {
  View,
  Text,
  Page,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import Header from './Header';
import P from './P';
import TableRowHeading from './TableRowHeading';
import TableRow2Cell from './TableRow2Cell';
import TableRowImage from './TableRowImage';

import type { FireReportData } from './Types';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40,
  },
  logo: {
    marginBottom: 10,
    width: '214px',
    height: '92px',
  },

  imageWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  image: {
    width: '230px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  imageTitle: {
    fontSize: 8,
  },
});

type Props = {
  data: FireReportData,
};

// Create Document Component
const FireReport = ({ data }: Props): React.Node => {
  const photoCounter = React.useRef(0);

  return (
    <Document debug>
      <Page size="A4" style={styles.page}>
        <Image source="/Venidi.png" style={styles.logo} />

        <Header variant="H1" text="Venidi - Fire Stopping Record" />

        <P
          text="The following report records reinstatement work undertaken within the
        common parts of the premise. Each individual block within a
        development will be issued with its own report to aid building
        management traceability and support the updating of building records."
        />

        <TableRow2Cell title="Site development Name" value={data.siteDevName} />

        <TableRow2Cell title="Block Name" value={data.blockName} />

        <TableRow2Cell title="Site Location" value={data.siteLocation} />

        <TableRow2Cell
          title="Report Start Date"
          value={data.reportStartDate.format('DD-MM-YYYY')}
        />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header variant="H2" text="Record of Installation" />

        <TableRowHeading text="Reinstatement Log" />

        <TableRowHeading text="Item" />

        {data.items.map((item, index) => (
          <>
            <TableRowHeading text={`Item ${index + 1}`} />

            <TableRow2Cell title="Operative name" value={item.operativeName} />

            <TableRow2Cell
              title="Re-Instatement date"
              value={item.reIntatementDate.format('DD-MM-YYYY')}
            />

            <TableRow2Cell
              title="Location of Fire Stopping"
              value={
                item.locationOfFireStopping.other
                  ? item.locationOfFireStopping.other
                  : item.locationOfFireStopping
              }
              variant="blue"
            />

            <TableRow2Cell
              title="Floor Level"
              value={item.floorLevel}
              variant="blue"
            />

            <TableRow2Cell
              title="Substrate Type"
              value={
                item.substrateType.other
                  ? item.substrateType.other
                  : item.substrateType
              }
              variant="blue"
            />

            <TableRow2Cell
              title="Substrate Depth"
              value={item.substrateDepth}
            />

            <TableRow2Cell
              title="ServiceType"
              value={
                item.serviceType.other
                  ? item.serviceType.other
                  : item.serviceType
              }
              variant="blue"
            />

            <TableRow2Cell
              title="Hole Diameter (Record diameter in mm)"
              value={item.holeDiameter}
            />

            <TableRow2Cell
              title="Cable Bundle/Single Cable Diameter/Services Diameter (Record diameter in mm)"
              value={item.bundle}
            />

            <TableRowImage
              title="Product Type Used"
              images={item.productTypeUsed}
              counter={photoCounter}
            />

            <TableRow2Cell
              title="Intended Product Resistance Time"
              value={item.intendedProductResistanceTime}
              variant="blue"
            />

            <P
              text="Please note: The information recorded supports ONLY that of the intended resistance time of the priduct inline with does not extend to the manufactures technical data and guidance. The resistance time does not extend to the performance of the substrate in the absence of a valid fire rating or, to where existing defects are present."
              variant="note"
            />

            <TableRowImage
              title="Pre Reinstatment Image"
              images={item.preReinstatmentImage}
              counter={photoCounter}
            />

            <TableRowImage
              title="Post Reinstatement Image"
              images={item.postReinstatmentImage}
              counter={photoCounter}
            />
          </>
        ))}

        <Header variant="H2" text="Sign Off" />

        <TableRowHeading text="Signatories" />

        <TableRowImage title="Operative Signature" images={data.signature} />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header variant="H2" text="Appendix" />

        <View style={styles.imageWrapper}>
          {data.allImages.map((fileData, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <View key={`allImages_${idx}`} style={styles.column}>
              <Image src={fileData.base64} style={styles.image} />

              <Text style={styles.imageTitle}>Photo {idx + 1}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default FireReport;
