// @flow
import * as React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import TableRow2CellBlue from './TableRow2CellBlue';

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    padding: '20px 10px 10px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
  },
  left: {
    flex: 0.8,
  },
  right: {
    flex: 0.2,
  },
  title: {
    fontSize: '14px',
    fontWeight: '800',
  },
  value: {
    fontSize: '12px',
    fontWeight: '100',
    textAlign: 'right',
  },
});

type Props = {|
  title: string,
  value: string,
  variant?: 'normal' | 'blue',
|};

const TableRow2Cell = ({ title, value, variant }: Props): React.Node => {
  switch (variant) {
    case 'blue': {
      return <TableRow2CellBlue title={title} value={value} />;
    }

    default: {
      return (
        <View style={styles.wrapper}>
          <View style={styles.left}>
            <Text style={styles.title}>{title}</Text>
          </View>

          <View style={styles.right}>
            <Text style={styles.value}>{value}</Text>
          </View>
        </View>
      );
    }
  }
};

TableRow2Cell.defaultProps = {
  variant: 'normal',
};

export default TableRow2Cell;
