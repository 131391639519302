// @flow

import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // $FlowFixMe
} from '@mui/material';
// $FlowFixMe
import SignatureCanvas from 'react-signature-canvas';

import FieldContainer from 'Components/FieldContainer';

type Props = {|
  label: string,
  error: boolean,
  onChange: Function,
  value?: string,
|};

const SignatureFormField = (props: Props): React.Node => {
  const { onChange, label, error, value } = props;
  const [open, setOpen] = React.useState(false);

  const sigCanvas = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveSignature = () => {
    onChange(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    setOpen(false);
  };

  return (
    <FieldContainer label={label} error={error}>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        Signature
      </Button>

      {value && (
        <Box sx={{ maxHeight: 200, width: '100%', marginTop: '5px' }}>
          <img
            src={value}
            alt="signature"
            style={{ width: '100%', maxHeight: '200px' }}
          />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box sx={{ height: 200, width: '100%', marginTop: '5px' }}>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="blue"
              canvasProps={{ className: 'sigCanvas' }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>

          <Button onClick={handleSaveSignature} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </FieldContainer>
  );
};

SignatureFormField.defaultProps = {
  value: undefined,
};

export default SignatureFormField;
