/* eslint-disable no-console */
// @flow

import * as React from 'react';

const useLocation = (debug: boolean = false) => {
  const [position, setPosition] = React.useState(null);
  const [location, setLocation] = React.useState(null);

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition);
    }
  }, []);

  React.useEffect(() => {
    if (position) {
      if (debug) {
        setLocation(
          'ul. "Professor Dr. Dimitar Atanasov" 15, 1680 g.k. Belite brezi, Sofia, Bulgaria (42.67794689999999, 23.2936516)',
        );

        return;
      }
      const fetchLocation = async () => {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=AIzaSyAhpzjVzWCa2HSkjFDuZ7K7elO4d43HEVg`,
          // `https://nominatim.openstreetmap.org/search.php?q=${position.coords.latitude}%2C${position.coords.longitude}&polygon_geojson=0&format=jsonv2`,
          { crossDomain: true },
        );

        if (response.status === 200) {
          const responseJson = await response.json();

          if (
            responseJson.results &&
            Array.isArray(responseJson.results) &&
            responseJson.results.length > 0
          ) {
            setLocation(
              `${responseJson.results[0].formatted_address} (${position.coords.latitude}, ${position.coords.longitude})`,
            );
          }
        }
      };

      fetchLocation();
    }
  }, [debug, position]);

  return location;
};

export default useLocation;
