// @flow
import * as React from 'react';
import {
  TextField,
  Box,
  // $FlowFixMe
} from '@mui/material';

import FieldContainer from 'Components/FieldContainer';

import { Controller } from 'react-hook-form';
import SignatureFormField from 'Components/SignatureFormField';

type Props = {|
  errors: Object,
  control: Object,
  activeStep: number,
  getValues: Function,
  setValue: Function,
|};

function Step4(props: Props): React.Node {
  const { activeStep, errors, control, getValues, setValue } = props;
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  return (
    <Box sx={{ display: activeStep === 3 ? 'auto' : 'none' }}>
      <FieldContainer
        htmlFor="generalNotesAndComments"
        label="General Notes and Comments"
        error={errors.generalNotesAndComments}
      >
        <Controller
          name="generalNotesAndComments"
          control={control}
          render={({ field }) => (
            <TextField {...field} variant="outlined" fullWidth />
          )}
        />
      </FieldContainer>

      <SignatureFormField
        label="Operative Signature"
        value={getValues('operativeSignature')}
        onChange={(val) => {
          setValue('operativeSignature', val);
          forceUpdate();
        }}
        error={errors.operativeSignature}
      />
    </Box>
  );
}

export default Step4;
