// @flow
import './App.css';

import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import FireReport from 'Routes/FireReport';
import InstallationAsBuild from 'Routes/InstallationAsBuild';
import Dashboard from 'Routes/Dashboard';
import Layout from 'Components/Layout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: '/fire-report',
        element: <FireReport />,
      },
      {
        path: '/iab',
        element: <InstallationAsBuild />,
      },
    ],
  },
]);

function App(): React.Node {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
