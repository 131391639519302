// @flow
import * as React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 20,
    fontSize: 12,
  },
  empty: {},
  note: {
    padding: 10,
    fontSize: '12px',
    fontWeight: '100',
    color: '#ccc',
  },
});

type Props = {|
  text: string,
  variant?: 'empty' | 'note',
|};

const P = ({ text, variant }: Props): React.Node => (
  <View style={styles.wrapper}>
    <Text style={styles[`${variant}`]}>{text}</Text>
  </View>
);

P.defaultProps = {
  variant: 'empty',
};

export default P;
