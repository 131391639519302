// @flow
import * as React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  H1Wrapper: {
    paddingTop: 20,
    fontSize: 12,
  },
  H1Text: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  H2Wrapper: {
    padding: 10,
    backgroundColor: '#ccc',
  },
  H2Text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

type Props = {|
  text: string,
  variant: 'H1' | 'H2',
|};

const Header = ({ text, variant }: Props): React.Node => (
  <View style={styles[`${variant}Wrapper`]}>
    <Text style={styles[`${variant}Text`]}>{text}</Text>
  </View>
);

export default Header;
