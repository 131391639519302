/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */

// TODO ADD WATERMARK
async function watermakImage(originalImage, watermarkImagePath) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const canvasWidth = originalImage.width;
  const canvasHeight = originalImage.height;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // initializing the canvas with the original image
  context.drawImage(originalImage, 0, 0, canvasWidth, canvasHeight);

  // loading the watermark image and transforming it into a pattern
  const result = await fetch(watermarkImagePath);
  const blob = await result.blob();
  const image = await createImageBitmap(blob);
  const pattern = context.createPattern(image, 'no-repeat');

  // translating the watermark image to the bottom right corner
  // context.translate(0, 0);
  // context.globalAlpha = 0.2;
  // context.rotate(30);
  context.translate(canvasWidth - image.width, canvasHeight - image.height);
  context.rect(0, 0, canvasWidth, canvasHeight);
  context.fillStyle = pattern;

  context.fill();

  return canvas.toDataURL('image/jpeg', 0.75);
}

export const convertFileToBase64 = (file, watterMark = false) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = (error) => reject(error);

    if (!watterMark) {
      reader.onload = () => {
        resolve({
          fileName: file.name,
          base64: reader.result,
        });
      };
    } else {
      // TODO: add watermark should optimize the quality.
      const img = new Image();

      img.onload = function () {
        const addWatermark = async () => {
          const res = await watermakImage(img, '/Venidi.png');

          resolve({
            fileName: file.name,
            base64: res,
          });
        };

        addWatermark();
      };
      // this is to setup loading the image
      reader.onloadend = function () {
        img.src = reader.result;
      };
    }

    // this is to read the file
    reader.readAsDataURL(file);
  });
