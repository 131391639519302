// @flow
import { Box, Button } from '@mui/material';
import { MENU } from 'Constants';
import * as React from 'react';
import { Link } from 'react-router-dom';

function Dashboard(): React.Node {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {MENU.map((menu) => (
        <Button
          key={menu.title}
          component={Link}
          to={menu.link}
          variant="contained"
          sx={{
            m: 2,
          }}
        >
          {menu.title}
        </Button>
      ))}
    </Box>
  );
}

export default Dashboard;
