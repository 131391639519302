// @flow
import * as React from 'react';
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  // $FlowFixMe
} from '@mui/material';

import FieldContainer from 'Components/FieldContainer';

import { Controller } from 'react-hook-form';

type Props = {|
  errors: Object,
  control: Object,
  activeStep: number,
|};

function Step3(props: Props): React.Node {
  const { activeStep, errors, control } = props;
  const [items, setItems] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent<*>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box sx={{ display: activeStep === 2 ? 'auto' : 'none' }}>
      {items.map((item, index) => (
        <Accordion
          expanded={expanded === `item${item}`}
          onChange={handleChange(`item${item}`)}
          sx={{ backgroundColor: 'transparent' }}
          key={`item_${item}`}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{ backgroundColor: '#dfdfdf' }}
          >
            <Typography>Deployment Area #{item}</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
            <FieldContainer
              htmlFor={`items.${index}.enclosureID`}
              label="Splitter Wall Box / The  enclosure ID"
              error={errors.items && errors.items[index]?.enclosureID}
            >
              <Controller
                name={`items.${index}.enclosureID`}
                control={control}
                render={({ field }) => (
                  <TextField {...field} variant="outlined" fullWidth />
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.enclosure`}
              label="Splitter wall box /The  enclosure"
              error={errors.items && errors.items[index]?.enclosure}
            >
              <Controller
                name={`items.${index}.enclosure`}
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="Confirmed"
                      control={<Radio />}
                      label="Confirmed"
                    />

                    <FormControlLabel
                      value="Re-location"
                      control={<Radio />}
                      label="Re-location"
                    />
                  </RadioGroup>
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.closeUpImageOfSplitterWallBox`}
              label="Close up image of Splitter Wall box"
              error={
                errors.items &&
                errors.items[index]?.closeUpImageOfSplitterWallBox
              }
            >
              <Controller
                name={`items.${index}.closeUpImageOfSplitterWallBox`}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    multiple
                  />
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.wideViewImageOfSplitterWallbox`}
              label="Wide view image of Splitter WallBox"
              error={
                errors.items &&
                errors.items[index]?.wideViewImageOfSplitterWallbox
              }
            >
              <Controller
                name={`items.${index}.wideViewImageOfSplitterWallbox`}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    multiple
                  />
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.cablingIsInstalled`}
              label="Cabling is installed in accordance to the original design"
              error={errors.items && errors.items[index]?.cablingIsInstalled}
            >
              <Controller
                control={control}
                name={`items.${index}.cablingIsInstalled`}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="Confirmed"
                      control={<Radio />}
                      label="Confirmed"
                    />

                    <FormControlLabel
                      value="Re-location"
                      control={<Radio />}
                      label="Re-location"
                    />
                  </RadioGroup>
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.closeUpImageOfCableRoutes`}
              label="Close up image of cable routs"
              error={
                errors.items && errors.items[index]?.closeUpImageOfCableRoutes
              }
            >
              <Controller
                name={`items.${index}.closeUpImageOfCableRoutes`}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    multiple
                  />
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.wideViewImageOfCableRoutes`}
              label="Wide view image of cable routes"
              error={
                errors.items && errors.items[index]?.wideViewImageOfCableRoutes
              }
            >
              <Controller
                name={`items.${index}.wideViewImageOfCableRoutes`}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    multiple
                  />
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.cablingEntering`}
              label="Cabling entering from the external face of the building has been wather sealed"
              error={errors.items && errors.items[index]?.cablingEntering}
            >
              <Controller
                control={control}
                name={`items.${index}.cablingEntering`}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="Confirmed"
                      control={<Radio />}
                      label="Confirmed"
                    />

                    <FormControlLabel
                      value="N/A to installation"
                      control={<Radio />}
                      label="N/A to installation"
                    />
                  </RadioGroup>
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.newOrExistingContainment`}
              label="Use of New or Existing containment is in accordance with the original design"
              error={
                errors.items && errors.items[index]?.newOrExistingContainment
              }
            >
              <Controller
                control={control}
                name={`items.${index}.newOrExistingContainment`}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="Confirmed"
                      control={<Radio />}
                      label="Confirmed"
                    />

                    <FormControlLabel
                      value="Re-location"
                      control={<Radio />}
                      label="Re-location"
                    />
                  </RadioGroup>
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.poeCspInstalled`}
              label="POE's/CSP's have been installed"
              error={errors.items && errors.items[index]?.poeCspInstalled}
            >
              <Controller
                control={control}
                name={`items.${index}.poeCspInstalled`}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="Confirmed"
                      control={<Radio />}
                      label="Confirmed"
                    />

                    <FormControlLabel
                      value="Re-location"
                      control={<Radio />}
                      label="Re-location"
                    />

                    <FormControlLabel
                      value="N/A to installation"
                      control={<Radio />}
                      label="N/A to installation"
                    />
                  </RadioGroup>
                )}
              />
            </FieldContainer>

            <FieldContainer
              htmlFor={`items.${index}.imageOfPoeCsp`}
              label="Image of POE/CSP installation"
              error={errors.items && errors.items[index]?.imageOfPoeCsp}
            >
              <Controller
                name={`items.${index}.imageOfPoeCsp`}
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    multiple
                  />
                )}
              />
            </FieldContainer>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button onClick={() => setItems([...items, items.length + 1])}>
        Add Item +
      </Button>
    </Box>
  );
}

export default Step3;
