// @flow
import * as React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
  },
  left: {
    flex: 0.8,
  },
  right: {
    flex: 0.2,
    backgroundColor: 'blue',
    padding: 10,
  },
  title: {
    color: 'blue',
    fontSize: '14px',
    fontWeight: '800',
  },
  value: {
    fontSize: '12px',
    fontWeight: '100',
    textAlign: 'center',
    color: '#fff',
  },
});

type Props = {|
  title: string,
  value: string,
|};

const TableRow2CellBlue = ({ title, value }: Props): React.Node => (
  <View style={styles.wrapper}>
    <View style={styles.left}>
      <Text style={styles.title}>{title}</Text>
    </View>

    <View style={styles.right}>
      <Text style={styles.value}>{value}</Text>
    </View>
  </View>
);

export default TableRow2CellBlue;
