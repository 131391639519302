/* eslint-disable no-nested-ternary */
// @flow
import * as React from 'react';

import {
  View,
  Text,
  Page,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import Header from './Header';
import TableRowHeading from './TableRowHeading';
import TableRow2Cell from './TableRow2Cell';
import TableRowImage from './TableRowImage';

import type { InstallationAsBuildData } from './Types';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40,
  },
  logo: {
    marginBottom: 10,
    width: '214px',
    height: '92px',
  },

  imageWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  image: {
    width: '230px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  imageTitle: {
    fontSize: 8,
  },
});

type Props = {
  data: InstallationAsBuildData,
};

// Create Document Component
const InstallationAsBuildDocument = ({ data }: Props): React.Node => {
  const photoCounter = React.useRef(0);

  return (
    <Document debug>
      <Page size="A4" style={styles.page}>
        <Image source="/Venidi.png" style={styles.logo} />

        <Header
          variant="H1"
          text="Venidi - DT SP: Installation As Built Summary V2"
        />

        <TableRow2Cell title="Site Name" value={data.siteName} />

        <TableRow2Cell title="Location" value={data.siteLocation} />

        <TableRow2Cell
          title="Report Start Date"
          value={data.reportStartDate.format('DD-MM-YYYY')}
        />

        <TableRow2Cell
          title="The Client (Fiber Provider)"
          value={data.fiberProvider}
        />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header variant="H2" text="Build Summary" />

        <TableRow2Cell
          title="Build Summary Number of units"
          value={data.numberOfUnits}
        />

        <TableRow2Cell title="Cabling type" value={data.cablingType} />

        <TableRow2Cell
          title="Lead Engineer for Build"
          value={data.leadEngineerForBuild}
        />

        <TableRow2Cell
          title="End Date of Stage One Cabling Work"
          value={data.endDateOfStageOneCablingWork.format('DD-MM-YYYY')}
        />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header variant="H2" text="As Built Inventory Log" />

        <TableRowHeading text="Deployment Area" />

        {data.items.map((item, index) => (
          <>
            <TableRowHeading text={`Deployment Area ${index + 1}`} />

            <TableRow2Cell
              title="Splitter wall box ID"
              value={item.enclosureID}
            />

            <TableRow2Cell title="Splitter wall box" value={item.enclosure} />

            <TableRowImage
              title="Close up image of Splitter wall box"
              images={item.closeUpImageOfSplitterWallBox}
              counter={photoCounter}
            />

            <TableRowImage
              title="Wide view image of Splitter wall box"
              images={item.wideViewImageOfSplitterWallbox}
              counter={photoCounter}
            />

            <TableRow2Cell
              title="Cabling is installed in accordance with the original desing"
              value={item.cablingIsInstalled}
              variant="blue"
            />

            <TableRowImage
              title="Close up image of cable route"
              images={item.closeUpImageOfCableRoutes}
              counter={photoCounter}
            />

            <TableRowImage
              title="Wide view image of cable route"
              images={item.wideViewImageOfCableRoutes}
              counter={photoCounter}
            />

            <TableRow2Cell
              title="Cabling entering from the external face of the building has been weather sealed"
              value={item.cablingEntering}
              variant="blue"
            />

            <TableRow2Cell
              title="Use of New or Existing containment is in accordance with the original design"
              value={item.newOrExistingContainment}
              variant="blue"
            />

            <TableRow2Cell
              title="Pattress Box/POE's/CSP's have been installed"
              value={item.poeCspInstalled}
              variant="blue"
            />
          </>
        ))}

        <Header variant="H2" text="Sign Off" />

        <TableRowHeading text="Signatories" />

        <TableRowImage title="Operative Signature" images={data.signature} />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header variant="H2" text="Appendix" />

        <View style={styles.imageWrapper}>
          {data.allImages.map((fileData, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <View key={`allImages_${idx}`} style={styles.column}>
              <Image src={fileData.base64} style={styles.image} />

              <Text style={styles.imageTitle}>Photo {idx + 1}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default InstallationAsBuildDocument;
