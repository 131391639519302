// @flow
import * as React from 'react';

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  wrapper: {
    padding: '20px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
  },
  title: {
    fontSize: '14px',
    fontWeight: '800',
  },
  image: {
    width: '92px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  imageTitle: {
    fontSize: 8,
  },
});

type Props = {|
  title: string,
  images: Array<string>,
  counter?: ?{
    current: number,
  },
|};

const TableRow2Image = ({ title, images, counter }: Props): React.Node => (
  <View style={styles.wrapper}>
    <Text style={styles.title}>{title}</Text>

    <View style={styles.row}>
      {images.map((fileData, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={`${title}_${idx}`} style={styles.column}>
          <Image src={fileData.base64} style={styles.image} />

          {counter && (
            // eslint-disable-next-line no-plusplus, no-param-reassign
            <Text style={styles.imageTitle}>Photo {++counter.current}</Text>
          )}
        </View>
      ))}
    </View>
  </View>
);

TableRow2Image.defaultProps = {
  counter: null,
};

export default TableRow2Image;
