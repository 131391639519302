// @flow
import * as React from 'react';
import {
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  // $FlowFixMe
} from '@mui/material';

import FieldContainer from 'Components/FieldContainer';

// $FlowFixMe
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { Controller } from 'react-hook-form';

type Props = {|
  errors: Object,
  control: Object,
  activeStep: number,
|};

function Step2(props: Props): React.Node {
  const { activeStep, errors, control } = props;

  return (
    <Box sx={{ display: activeStep === 1 ? 'auto' : 'none' }}>
      <FieldContainer
        htmlFor="numberOfUnits"
        label="Build Summary Number of units"
        error={errors.numberOfUnits}
      >
        <Controller
          name="numberOfUnits"
          control={control}
          render={({ field }) => (
            <TextField {...field} variant="outlined" fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="cablingType"
        label="Cabling Type"
        error={errors.cablingType}
      >
        <Controller
          control={control}
          name="cablingType"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel value="FTTH" control={<Radio />} label="FTTH" />

              <FormControlLabel
                value="Discreet Cabling"
                control={<Radio />}
                label="Discreet Cabling"
              />
            </RadioGroup>
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="leadEngineerForBuild"
        label="Lead Engineer For Build"
        error={errors.leadEngineerForBuild}
      >
        <Controller
          name="leadEngineerForBuild"
          control={control}
          render={({ field }) => (
            <TextField {...field} variant="outlined" fullWidth />
          )}
        />
      </FieldContainer>

      <FieldContainer
        htmlFor="endDateOfStageOneCablingWork"
        label="End Date of Stage One Cabling Work"
        error={errors.endDateOfStageOneCablingWork}
      >
        <Controller
          name="endDateOfStageOneCablingWork"
          control={control}
          defaultValue=""
          render={({ field: { ref, ...rest } }) => (
            <MobileDatePicker
              id="endDateOfStageOneCablingWork-dialog"
              inputFormat="MM/DD/YYYY"
              renderInput={(params) => <TextField {...params} fullWidth />}
              {...rest}
            />
          )}
        />
      </FieldContainer>
    </Box>
  );
}

export default Step2;
