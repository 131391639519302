// @flow
import * as React from 'react';
// $FlowFixMe
import { Box, InputLabel } from '@mui/material';

type Props = {|
  label?: string,
  htmlFor?: string,
  children: ?React.Node,
  error?: boolean,
|};

const FieldContainer = (props: Props): React.Node => {
  const { label, htmlFor, children, error } = props;

  return (
    <Box
      sx={{
        backgroundColor: error ? '#f8b3b3' : '#fff',
        borderRadius: '10px',
        padding: '10px',
        marginBottom: '10px',
      }}
    >
      {label && <InputLabel htmlFor={htmlFor}>{label}</InputLabel>}

      {children}
    </Box>
  );
};

FieldContainer.defaultProps = {
  label: undefined,
  htmlFor: undefined,
  error: false,
};

export default FieldContainer;
