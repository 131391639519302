// @flow
import * as React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  wrapper: {
    padding: '20px 10px 10px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
  },
});

type Props = {|
  text: string,
|};

const TableRowHeading = ({ text }: Props): React.Node => (
  <View style={styles.wrapper}>
    <Text style={styles.text}>{text}</Text>
  </View>
);

export default TableRowHeading;
